import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import MDBox from "components/MDBox";
import React from "react";
import Typography from "@mui/material/Typography";

function LiveMatchFeed() {
  const liveMatches = [
    { match: "Team A vs Team B", score: "2-1", status: "Live" },
    { match: "Team C vs Team D", score: "0-0", status: "1st Half" },
  ];

  return (
    <Card>
      <MDBox pt={2} px={2}>
        <Typography variant="h6" fontWeight="medium">
          Live Match Feed
        </Typography>
      </MDBox>
      <CardContent>
        {liveMatches.map((match, index) => (
          <MDBox key={index} py={1}>
            <Typography variant="body2">{match.match}</Typography>
            <Typography variant="body2" color="textSecondary">
              {match.score} - {match.status}
            </Typography>
          </MDBox>
        ))}
      </CardContent>
    </Card>
  );
}

export default LiveMatchFeed;
