import axios from "axios";

// import axios from 'axios';

// Define the base URL
// eslint-disable-next-line prettier/prettier
const BASEurl = 'https://bettings-429609.dt.r.appspot.com/api/Gaming';

// Define the method to create a user
export const createUser = async (loginDto) => {
  try {
    const response = await axios.post(`${BASEurl}/addSignup`, loginDto);
    console.log("User created successfully:", response.data);
    return response.data;
  } catch (error) {
    console.error("There was an error creating the user!", error);
    throw error; // Re-throw the error to handle it in the calling function if needed
  }
};
