/* eslint-disable prettier/prettier */
export const employeeColumn = [
  { Header: "Username", accessor: "userName", align: "left" },
  { Header: "Email", accessor: "email", align: "left" },
  { Header: "Status", accessor: "status", align: "center" },
];

export const employeerows = [
  { username: "johndoe", email: "johndoe@example.com", status: "Active" },
  { username: "janedoe", email: "janedoe@example.com", status: "Inactive" },
];

export const gameColumn = [
  { Header: "Name", accessor: "gameName", align: "left" },
  { Header: "Points", accessor: "gamePoints", align: "left" },
  { Header: "Start Date", accessor: "startDate", align: "center" },
  { Header: "End Date", accessor: "endDate", align: "center" },
];

export const gamerows = [
  { gameName: "johndoe", gamePoints: "500", startDate: "06/20/2024", endDate: "08/30/2024" },
  { gameName: "johndoe", gamePoints: "400", startDate: "08/27/2024", endDate: "08/30/2024" },
  { gameName: "johndoe", gamePoints: "500", startDate: "08/27/2024", endDate: "08/30/2024" },
];

export const scoreColumn = [
  { Header: "Employeename", accessor: "name", align: "left" },
  { Header: "GamePoints", accessor: "points", align: "left" },
  { Header: "Loss", accessor: "loss", align: "center" },
  { Header: "Win", accessor: "win", align: "center" },
];

export const scorerows = [
  { name: "johndoe", points: "300", loss: "200", win: "100" },
  { name: "johndoe", points: "300", loss: "200", win: "100" },
];

export const fixtureColumn = [
  { Header: "Selectgame", accessor: "gameId", align: "left" },
  { Header: "Team1name", accessor: "team1Id", align: "left" },
  { Header: "Team2name", accessor: "team2Id", align: "center" },
  { Header: "Fixdate", accessor: "setDate", align: "center" },
];

export const fixturerows = [
  { gameName: "badminton", team1: "gee",  team2: "rose",startDate:"21-07-2024" },
  { gameName: "Cricket", team1: "gee",  team2: "rose",startDate:"21-07-2024" },
];

export const teamColumns = [
  { Header: "Game", accessor: "gameId", align: "left" },
  { Header: "Team Name", accessor: "teamName", align: "left" },
  { Header: "Team Members", accessor: "team1Ids", align: "center" },
  { Header: "Actions", accessor: "actions", align: "center" },
];
 
export const teamRows = [
  {
    gameName: "Cricket",
    teamName: "Warriors",
    teamMembers: "John Doe, Jane Smith, Bob Johnson",
    actions: "",
  },
  {
    gameName: "Football",
    teamName: "Champions",
    teamMembers: "Alice Brown, David Clark, Eva White",
    actions: "",
  },
];

// layouts/tables/data/employeeTableData.js
export const availablegamesColumn = [
  { Header: "Game", accessor: "game", align: "left" },
  { Header: "Team 1", accessor: "team1", align: "left" },
  { Header: "Team 2", accessor: "team2", align: "left" },
  { Header: "Date", accessor: "Date", align: "left" },
];
 
export const availablegamesrows = [
  { game: "Soccer", team1: "Team A", team2: "Team B", Date: "07-01-2024"  },
  { game: "Basketball", team1: "Team C", team2: "Team D",Date: "08-02-2024" },
];
