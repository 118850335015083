import axios from "axios";

const BASE_URL = "https://bettings-429609.dt.r.appspot.com/api/Gaming";

export const verifyOtp = async (email, otp) => {
  try {
    const response = await axios.get(`${BASE_URL}/verify`, {
      params: { email, otp },
    });
    return response.data;
  } catch (error) {
    console.error("Error verifying OTP:", error);
    throw error;
  }
};
