import axios from "axios";

const BASE_URL = "https://bettings-429609.dt.r.appspot.com/api/Gaming";

export const sendOtp = async (loginId, email, otp) => {
  try {
    const response = await axios.post(`${BASE_URL}/createOtp`, {
      loginId,
      email,
      otp,
    });
    return response.data;
  } catch (error) {
    console.error("Failed to send OTP:", error);
    throw error;
  }
};
