/* eslint-disable prettier/prettier */
/** ========================================================= 
 * Material Dashboard 2 React - v2.2.0
 =========================================================  
 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)
  
 Coded by www.creative-tim.com   
 =========================================================
  
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Custom components
import Fixtures from './Fixtures'; // Import the GameTable component

function Notifications() {
  return (
    <DashboardLayout>
      <DashboardNavbar/>
      <MDBox mt={8}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Fixtures /> {/* Render the GameTable component */}
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Notifications;