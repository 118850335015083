import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CommonTables from "Common/Table";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Paper from "@mui/material/Paper";
import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { deleteGame, getAllGames, saveGame, updateGame } from "JavaApi/GameApi";
import { gameColumn } from "layouts/tables/data/employeeTableData";

const GameTable = () => {
  const [games, setGames] = useState([]);
  const [formData, setFormData] = useState({
    gameName: "",
    gamePoints: "",
    startDate: "",
    endDate: "",
  });
  const [isEditing, setIsEditing] = useState(false);
  const [editGameId, setEditGameId] = useState(null);

  useEffect(() => {
    const fetchGames = async () => {
      try {
        const gamesData = await getAllGames();
        // Filter games to only include those with status true
        const filteredGames = gamesData.filter((game) => game.status === true);
        setGames(filteredGames);
        console.log("Fetched games data:", filteredGames);
      } catch (error) {
        console.error("There was an error fetching the games!", error);
      }
    };

    fetchGames();
  }, []);

  const handleFormChange = (e) => {
    const newFormData = { ...formData, [e.target.name]: e.target.value };
    setFormData(newFormData);
    console.log("Form data updated:", newFormData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (isEditing) {
        await updateGame(editGameId, formData); // Update game by gameId
        const updatedGames = games.map((game) =>
          game.gameId === editGameId ? { ...game, ...formData } : game
        );
        setGames(updatedGames);
        setIsEditing(false);
        setEditGameId(null);
        console.log("Updated games data after editing:", updatedGames);
      } else {
        const newGame = await saveGame(formData);
        const newGames = [...games, newGame];
        setGames(newGames);
        console.log("Updated games data after adding new game:", newGames);
      }
      setFormData({ gameName: "", gamePoints: "", startDate: "", endDate: "" });
    } catch (error) {
      console.error(
        "There was an error submitting the form!",
        error.response?.data || error.message
      );
    }
  };

  const handleEdit = (rowIndex) => {
    const gameToEdit = games[rowIndex];
    setFormData({
      gameName: gameToEdit.gameName,
      gamePoints: gameToEdit.gamePoints,
      startDate: gameToEdit.startDate,
      endDate: gameToEdit.endDate,
    });
    setIsEditing(true);
    setEditGameId(gameToEdit.gameId); // Set the gameId for the game being edited
    console.log("Editing game at index:", rowIndex, "Data:", gameToEdit);
  };

  const handleDelete = async (rowIndex) => {
    try {
      const gameId = games[rowIndex].gameId;
      console.log("Deleting game gameId:", gameId);
      await deleteGame(gameId); // Soft delete game by gameId
      const updatedGames = games
        .map((game, index) => (index === rowIndex ? { ...game, status: false } : game))
        .filter((game) => game.status === true);
      setGames(updatedGames);
      console.log("Updated games data after deletion:", updatedGames);
    } catch (error) {
      console.error("There was an error deleting the game!", error.response?.data || error.message);
    }
  };

  const updatedRows = games
    .filter((row) => row.status === true) // Filter out deleted games
    .map((row, index) => ({
      ...row,
      actions: (
        <>
          <IconButton onClick={() => handleEdit(index)}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleDelete(index)}>
            <DeleteIcon />
          </IconButton>
        </>
      ),
    }));

  return (
    <Grid container spacing={3}>
      {/* Table Section - Left Column */}
      <CommonTables
        name={"Game Table"}
        columns={[...gameColumn, { Header: "Actions", accessor: "actions" }]}
        rows={updatedRows}
      />

      {/* Form Section - Right Column */}
      <Grid item xs={12} md={4}>
        <Paper elevation={3} sx={{ p: 2, borderRadius: 3 }}>
          <MDBox
            mx={2}
            mt={-5}
            py={3}
            px={2}
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
          >
            <MDTypography variant="h6" color="white">
              {isEditing ? "Edit Game" : "Create Game"}
            </MDTypography>
          </MDBox>
          <MDBox pt={3}>
            <form onSubmit={handleSubmit}>
              <TextField
                label="Game Name"
                name="gameName"
                value={formData.gameName}
                onChange={handleFormChange}
                fullWidth
                margin="normal"
                variant="outlined"
              />
              <TextField
                label="Points"
                name="gamePoints"
                type="number"
                value={formData.gamePoints}
                onChange={handleFormChange}
                fullWidth
                margin="normal"
                variant="outlined"
              />
              <TextField
                label="Start Date"
                name="startDate"
                type="date"
                value={formData.startDate}
                onChange={handleFormChange}
                fullWidth
                margin="normal"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                label="End Date"
                name="endDate"
                type="date"
                value={formData.endDate}
                onChange={handleFormChange}
                fullWidth
                margin="normal"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <Box mt={2}>
                <Button type="submit" variant="contained" fullWidth sx={{ color: "#fff" }}>
                  {isEditing ? "Update" : "Submit"}
                </Button>
              </Box>
            </form>
          </MDBox>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default GameTable;
