import Card from "@mui/material/Card";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import Footer from "examples/Footer";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import React from "react";
import CommonTables from "Common/Table";
import { scoreColumn, scorerows } from "../tables/data/employeeTableData";
import Score from "./components/Score";

// import projectsTableData from "layouts/tables/data/projectsTableData";

function Overview() {
  // const { columns: projectColumns, rows: projectRows } = projectsTableData();

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Score />
      </MDBox>
    </DashboardLayout>
  );
}

export default Overview;
