import axios from "axios";

const BASE_URL = "https://bettings-429609.dt.r.appspot.com/api/Gaming"; // Replace with your actual backend URL

export const updatePassword = async (id, newPassword) => {
  const response = await axios.put(`${BASE_URL}/${id}/edit/password`, {
    password: newPassword,
  });
  return response.data;
};
