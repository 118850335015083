import axios from "axios";

// Define the base URL for available games-related API endpoints
const BASEurl = "https://bettings-429609.dt.r.appspot.com/api/Gaming";

export const getAllScoreGames = async () => {
  try {
    const response = await axios.get(`${BASEurl}/getAllBet`);
    return response.data;
  } catch (error) {
    console.error("There was an error fetching all available games!", error);
    throw error;
  }
};
