import axios from "axios";

// Define the base URL for game-related API endpoints
const BASEurl = "https://bettings-429609.dt.r.appspot.com/api/Gaming";

// Function to save a new game
export const saveGame = async (gameData) => {
  try {
    const response = await axios.post(`${BASEurl}/saveGame`, gameData);
    console.log("Game saved successfully:", response.data);
    return response.data;
  } catch (error) {
    console.error("There was an error saving the game!", error);
    throw error;
  }
};

// Function to get all games
export const getAllGames = async () => {
  try {
    const response = await axios.get(`${BASEurl}/getAllGames`);
    return response.data;
  } catch (error) {
    console.error("There was an error fetching all games!", error);
    throw error;
  }
};

// Function to get game points by gameId
export const getGamePoints = async (gameId) => {
  console.log(gameId);
  try {
    const response = await axios.get(`${BASEurl}/${gameId}/getGameById`);
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the game points!", error);
    throw error;
  }
};
// Function to update a game by gameId
export const updateGame = async (gameId, gameData) => {
  try {
    const response = await axios.put(`${BASEurl}/${gameId}/updateGame`, gameData);
    console.log("Game updated successfully:", response.data);
    return response.data;
  } catch (error) {
    console.error("There was an error updating the game!", error);
    throw error;
  }
};

// Function to delete (soft delete) a game by gameId
export const deleteGame = async (gameId) => {
  try {
    const response = await axios.put(`${BASEurl}/${gameId}/deleteGame`);
    console.log("Game deleted (soft delete) successfully:", response.data);
    return response.data;
  } catch (error) {
    console.error("There was an error deleting the game!", error);
    throw error;
  }
};
