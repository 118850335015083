import CommonTables from "Common/Table";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import React, { useEffect, useState } from "react";
import { getAllUsers } from "JavaApi/UserviewApi";
import { employeeColumn } from "./data/employeeTableData";

/* eslint-disable react/prop-types */

function Tables({ setAvailableEmployeesCount }) {
  const [employees, setEmployees] = useState([]);

  useEffect(() => {
    console.log("Component mounted, calling fetchEmployees");
    fetchEmployees();
  }, []);

  const fetchEmployees = async () => {
    console.log("fetchEmployees function called");
    try {
      console.log("Calling getAllUsers API");
      const data = await getAllUsers();
      console.log("Raw data from API:", data);

      if (!Array.isArray(data)) {
        console.error("Data is not an array:", data);
        return;
      }

      console.log("Filtering and mapping data", data);
      const formattedData = data
        .filter((employee) => {
          return employee.status === true && employee.isAdmin === false;
        })
        .map((employee) => {
          console.log("Mapping employee:", employee);
          return {
            userName: employee.userName,
            email: employee.email,
            status: "Active",
            is_admin: "No",
          };
        });

      console.log("Formatted data:", formattedData);
      console.log("Setting employees state");
      setEmployees(formattedData);
      console.log("Setting availableEmployeesCount");
      setAvailableEmployeesCount(formattedData.length);
    } catch (error) {
      console.error("Error in fetchEmployees:", error);
    }
  };

  console.log("Current employees state:", employees);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <CommonTables name={"Employee Table"} columns={employeeColumn} rows={employees} />
      </MDBox>
    </DashboardLayout>
  );
}

export default Tables;
