import MDBox from "components/MDBox";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { setLayout, useMaterialUIController } from "context";
import { Navigate, useLocation } from "react-router-dom";

/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

function DashboardLayout({ children }) {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav } = controller;
  const { pathname } = useLocation();

  const isAdmin = localStorage.getItem("isAdmin");

  useEffect(() => {
    if (isAdmin) {
      setLayout(dispatch, "dashboard");
    }
  }, [pathname, isAdmin, dispatch]);

  if (!isAdmin) {
    return <Navigate to="/Availablegames" />;
  }
  const adm = localStorage.getItem("isAdmin");
  console.log(adm);
  return (
    <MDBox
      sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
        p: 3,
        position: "relative",
        [breakpoints.up("xl")]: {
          marginLeft:
            localStorage.getItem("isAdmin") === "false"
              ? pxToRem(0)
              : miniSidenav
              ? pxToRem(120)
              : pxToRem(274),
          transition: transitions.create(["margin-left", "margin-right"], {
            easing: transitions.easing.easeInOut,
            duration: transitions.duration.standard,
          }),
        },
      })}
    >
      {children}
    </MDBox>
  );
}

// Typechecking props for the DashboardLayout
DashboardLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DashboardLayout;
