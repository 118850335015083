import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Grid from "@mui/material/Grid";
import LiveMatchFeed from "layouts/dashboard/components/LiveMatchFeed";
import MDBox from "components/MDBox";
import OrdersOverview from "layouts/dashboard/components/OrdersOverview";
import Projects from "layouts/dashboard/components/Projects";
import React, { useEffect, useState } from "react";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import { getAllFixtures } from "JavaApi/FixtureApi";
import { getAllGames } from "JavaApi/GameApi";
import { getAllTeams } from "JavaApi/TeamApi";
import { getAllUsers } from "JavaApi/TeamApi";
import { Link } from "react-router-dom";

// Import the getAllEmployees API function
function Dashboard() {
  const [availableEmployeesCount, setAvailableEmployeesCount] = useState(0);
  const [teamCount, setTeamCount] = useState(0);
  const [availableGamesCount, setAvailableGamesCount] = useState(0);
  const [availableFixturesCount, setAvailableFixturesCount] = useState(0);

  useEffect(() => {
    const fetchTeamCount = async () => {
      try {
        const teams = await getAllTeams();
        const activeTeams = teams.filter((team) => team.status === true);
        setTeamCount(activeTeams.length);
      } catch (error) {
        console.error("Error fetching team count:", error);
      }
    };

    const fetchAvailableGamesCount = async () => {
      try {
        const games = await getAllGames();
        const activeGames = games.filter((game) => game.status === true);
        setAvailableGamesCount(activeGames.length);
      } catch (error) {
        console.error("Error fetching available games count:", error);
      }
    };

    const fetchAvailableEmployeesCount = async () => {
      try {
        const employees = await getAllUsers();
        const activeEmployees = employees.filter(
          (employee) => employee.status === true && employee.isAdmin === false
        );
        setAvailableEmployeesCount(activeEmployees.length);
      } catch (error) {
        console.error("Error fetching available employees count:", error);
      }
    };
    const fetchAvailableFixturesCount = async () => {
      try {
        const fixtures = await getAllFixtures();
        const activeFixtures = fixtures.filter((fixture) => fixture.status === true);
        setAvailableFixturesCount(activeFixtures.length);
      } catch (error) {
        console.error("Error fetching available fixtures count:", error);
      }
    };

    fetchTeamCount();
    fetchAvailableGamesCount();
    fetchAvailableEmployeesCount();
    fetchAvailableFixturesCount();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4} lg={2.4}>
            <MDBox mb={1.5}>
              <Link to="/employee" style={{ textDecoration: "none" }}>
                <ComplexStatisticsCard
                  color="dark"
                  icon="people"
                  count={availableEmployeesCount}
                  percentage={{
                    color: "success",
                    // amount: "+55%",
                    label: "Available Employees",
                  }}
                />
              </Link>
            </MDBox>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2.4}>
            <MDBox mb={1.5}>
              <Link to="/game" style={{ textDecoration: "none" }}>
                <ComplexStatisticsCard
                  icon="sports"
                  count={availableGamesCount}
                  percentage={{
                    color: "success",
                    // amount: "73%",
                    label: "Available Games",
                  }}
                />
              </Link>
            </MDBox>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2.4}>
            <MDBox mb={1.5}>
              <Link to="/Team" style={{ textDecoration: "none" }}>
                <ComplexStatisticsCard
                  color="success"
                  icon="person_add"
                  count={teamCount}
                  percentage={{
                    color: "success",
                    // amount: "81%",
                    label: "Team List",
                  }}
                />
              </Link>
            </MDBox>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2.4}>
            <MDBox mb={1.5}>
              <Link to="/Fixtures" style={{ textDecoration: "none" }}>
                <ComplexStatisticsCard
                  color="primary"
                  icon="notifications"
                  count={availableFixturesCount}
                  percentage={{
                    color: "success",
                    // amount: "56%",
                    label: "Today's Matches",
                  }}
                />
              </Link>
            </MDBox>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2.4}>
            <MDBox mb={1.5}>
              <Link to="/score" style={{ textDecoration: "none" }}>
                <ComplexStatisticsCard
                  color="warning"
                  icon="scoreboard"
                  // count="120"
                  percentage={{
                    color: "success",
                    // amount: "45%",
                    label: "Current Scores",
                  }}
                />
              </Link>
            </MDBox>
          </Grid>
        </Grid>
        <MDBox mt={4.5}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <MDBox mb={3}>
                <ReportsBarChart
                  color="info"
                  title="Top Bets Placed"
                  description="Number of bets placed on different sports"
                  date="Updated just now"
                  chart={reportsBarChartData}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        {/* <MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={8}>
              <Projects />
            </Grid>
            <Grid item xs={12} lg={4}>
              <OrdersOverview />
            </Grid>
 
            <Grid item xs={12}>
              <LiveMatchFeed />
            </Grid>
          </Grid>
        </MDBox> */}
      </MDBox>
      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default Dashboard;
