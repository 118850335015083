/* eslint-disable react/prop-types */
import React from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import DataTable from "examples/Tables/DataTable"; // Ensure this is the correct import for your DataTable
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { styled } from "@mui/material/styles";

// Define custom styles for the table header
const StyledHeader = styled("div")({
  fontSize: "16px",
});

const enhanceColumns = (columns) => {
  return columns.map((column) => ({
    ...column,
    Header: () => <StyledHeader>{column.Header}</StyledHeader>,
  }));
};

function Modifytable({ name, columns, rows }) {
  const styledColumns = enhanceColumns(columns);

  return (
    <Grid item xs={12} md={12}>
      <Card>
        <MDBox
          mx={2}
          mt={-3}
          py={3}
          px={2}
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
        >
          <MDTypography variant="h6" color="white">
            {name}
          </MDTypography>
        </MDBox>
        <MDBox pt={3}>
          <DataTable
            table={{ columns: styledColumns, rows }}
            isSorted={false}
            entriesPerPage={false}
            showTotalEntries={false}
            noEndBorder
          />
        </MDBox>
      </Card>
    </Grid>
  );
}

export default Modifytable;
