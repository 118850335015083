import Alert from "@mui/material/Alert";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import Modal from "@mui/material/Modal";
import React, { useState } from "react";
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { forgotPassword } from "JavaApi/ForgotpasswordApi";
import { sendOtp } from "JavaApi/OtpApi";
import { getAllUsers } from "JavaApi/TeamApi";
import { updatePassword } from "JavaApi/UpdatepassApi";
import { verifyOtp } from "JavaApi/VerifyOtpApi";
import { useNavigate } from "react-router-dom";

// Modal style
const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 3,
};

function Basic() {
  const navigate = useNavigate();
  const [loginId, setLoginId] = useState("");
  const [email, setEmail] = useState("");
  const [open, setOpen] = useState(false);
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [loading, setLoading] = useState(false);
  const [otpLoading, setOtpLoading] = useState(false);
  const [newPasswordModalOpen, setNewPasswordModalOpen] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState({ type: "", text: "" });
  const [generatedOtp, setGeneratedOtp] = useState("");

  const handleOpen = async () => {
    setLoading(true);
    try {
      const data = await forgotPassword(email);
      setLoading(false);
      setOpen(true);
      // setMessage({ type: "success", text: "OTP sent to your email" });

      const generatedOtp = Math.floor(1000 + Math.random() * 9000).toString();
      setGeneratedOtp(generatedOtp); // Store generated OTP in state

      const storedEmail = localStorage.getItem("username");
      console.log("Stored email:", storedEmail);

      const users = await getAllUsers();
      const user = users.find((u) => u.email === storedEmail);
      if (!user) {
        throw new Error("User not found");
      }
      const loginId = user.id;
      localStorage.setItem("loginId", loginId);

      await sendOtp(loginId.toString(), storedEmail, generatedOtp);

      setGeneratedOtp(generatedOtp);
    } catch (error) {
      setLoading(false);
      setMessage({ type: "error", text: "Failed to send OTP. Please try again." });
      console.error("Error in handleOpen:", error);
    }
  };

  const handleClose = () => setOpen(false);

  const handleOtpChange = (index) => (e) => {
    const newOtp = [...otp];
    newOtp[index] = e.target.value;
    setOtp(newOtp);

    if (e.target.value && index < 3) {
      const nextInput = document.getElementById(`otp-${index + 1}`);
      if (nextInput) nextInput.focus();
    }
  };

  const handleVerify = async (e) => {
    e.preventDefault();
    setOtpLoading(true);
    const enteredOtp = otp.join("");
    try {
      console.log("Verifying OTP for email:", email);
      console.log("Entered OTP:", enteredOtp);

      const result = await verifyOtp(email, enteredOtp);
      console.log("OTP verification result:", result);

      if (result && result.verified) {
        handleClose();
        setNewPasswordModalOpen(true);
        setMessage({ type: "success", text: "OTP verified successfully" });
      } else {
        setMessage({ type: "error", text: "Invalid OTP. Please try again." });
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      setMessage({ type: "error", text: "Failed to verify OTP. Please try again." });
    } finally {
      setOtpLoading(false);
    }
  };

  const handleNewPasswordChange = (e) => setNewPassword(e.target.value);
  const handleConfirmPasswordChange = (e) => setConfirmPassword(e.target.value);

  const validatePassword = (password) => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return regex.test(password);
  };

  const handleNewPasswordSubmit = async (e) => {
    e.preventDefault();
    if (!validatePassword(newPassword)) {
      setMessage({
        type: "error",
        text: "Password must contain at least 8 characters, including uppercase, lowercase, numbers, and special characters.",
      });
      return;
    }
    if (newPassword !== confirmPassword) {
      setMessage({ type: "error", text: "Passwords do not match" });
      return;
    }

    try {
      const loginId = localStorage.getItem("loginId");

      if (!loginId) {
        setMessage({ type: "error", text: "User ID not found. Please try again." });
        return;
      }

      const result = await updatePassword(loginId, newPassword);

      if (result) {
        setMessage({
          type: "success",
          text: "Password reset successfully. Redirecting to home page...",
        });
        setTimeout(() => {
          navigate("/Availablegames"); // Replace '/dashboard' with your actual dashboard route
        }, 2000);
      } else {
        throw new Error("Failed to update password");
      }
    } catch (error) {
      console.error("Error updating password:", error);
      setMessage({ type: "error", text: "Failed to reset password. Please try again." });
    }
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Forgot Password
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                onClick={handleOpen}
                disabled={loading || !email}
              >
                {loading ? <CircularProgress size={24} color="inherit" /> : "Send OTP"}
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
        {message.text && !open && !newPasswordModalOpen && (
          <MDBox pt={2} pb={2} px={3}>
            <Alert severity={message.type}>{message.text}</Alert>
          </MDBox>
        )}
      </Card>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <MDBox
            mx={2}
            mt={-7}
            py={3}
            px={2}
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
            textAlign="center"
          >
            <MDTypography variant="h6" color="white">
              Verify OTP
            </MDTypography>
          </MDBox>
          <MDBox pt={3}>
            <form onSubmit={handleVerify}>
              <MDBox mb={2} display="flex" justifyContent="space-between">
                {[0, 1, 2, 3].map((index) => (
                  <MDInput
                    key={index}
                    id={`otp-${index}`}
                    type="text"
                    inputProps={{
                      maxLength: 1,
                      style: { textAlign: "center" },
                    }}
                    sx={{ width: "40px" }}
                    value={otp[index]}
                    onChange={handleOtpChange(index)}
                  />
                ))}
              </MDBox>
              {message.text && (
                <MDBox pt={2} pb={2} px={3}>
                  <Alert severity={message.type}>{message.text}</Alert>
                </MDBox>
              )}
              <MDBox mt={4} mb={1}>
                <MDButton
                  type="submit"
                  variant="gradient"
                  color="info"
                  fullWidth
                  disabled={otpLoading}
                >
                  {otpLoading ? <CircularProgress size={24} color="inherit" /> : "Verify"}
                </MDButton>
              </MDBox>
              {/* Display the generated OTP for testing purposes */}
              <MDTypography variant="body2" color="text.secondary" textAlign="center" mt={2}>
                Generated OTP: {generatedOtp}
              </MDTypography>
            </form>
          </MDBox>
        </Box>
      </Modal>

      <Modal
        open={newPasswordModalOpen}
        onClose={() => setNewPasswordModalOpen(false)}
        aria-labelledby="new-password-modal-title"
        aria-describedby="new-password-modal-description"
      >
        <Box sx={modalStyle}>
          <MDBox
            mx={2}
            mt={-7}
            py={3}
            px={2}
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
            textAlign="center"
          >
            <MDTypography variant="h6" color="white">
              Enter New Password
            </MDTypography>
          </MDBox>
          <MDBox pt={3}>
            <form onSubmit={handleNewPasswordSubmit}>
              <MDBox mb={2}>
                <MDInput
                  type="password"
                  label="New Password"
                  fullWidth
                  value={newPassword}
                  onChange={handleNewPasswordChange}
                />
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  type="password"
                  label="Confirm Password"
                  fullWidth
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                />
              </MDBox>
              {message.text && (
                <MDBox pt={2} pb={2} px={3}>
                  <Alert severity={message.type}>{message.text}</Alert>
                </MDBox>
              )}
              <MDBox mt={4} mb={1}>
                <MDButton type="submit" variant="gradient" color="info" fullWidth>
                  Submit
                </MDButton>
              </MDBox>
            </form>
          </MDBox>
        </Box>
      </Modal>
    </BasicLayout>
  );
}

export default Basic;
