import axios from "axios";

// src/api/index.js

// Define the base URL
const BASEurl = "https://bettings-429609.dt.r.appspot.com/api/Gaming";

// In ForgotpasswordApi.js
export const forgotPassword = async (email) => {
  try {
    const response = await axios.post(`${BASEurl}/forgotByEmail`, { email });
    return response.data; // Assuming this now includes the loginId if available
  } catch (error) {
    console.error("There was an error with the forgot password request!", error);
    throw error;
  }
};
