import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import React, { useEffect, useState } from "react";
import { saveBettings, updateBettings, getBettingtableById } from "JavaApi/BettingApi"; // Adjust the import as needed
import { getAllGames, getGamePoints } from "JavaApi/GameApi";
import { getAllTeams, getAllUsers } from "JavaApi/TeamApi";
import { useLocation, useNavigate } from "react-router-dom";

import {
  Backdrop,
  Box,
  Button,
  Fade,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  TextField,
} from "@mui/material";

const Betting = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const game = location.state?.fixture || {};
  const update = location.state?.update || null;
  const betId = location.state?.betId || null; // Assuming betId is passed if it's an update

  const [formData, setFormData] = useState({
    fixtureId: game.fixtureId || "",
    gameName: game.gameId || "",
    gamePoints: game.gamePoints || "",
    team: game.team1Id || "",
    result: game.results || "pending",
    bettingPoints: "",
    date: game.setDate || "",
  });

  const [open, setOpen] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(""); // New state for error messages

  const [teamMap, setTeamMap] = useState({});
  const [gameMap, setGameMap] = useState({});
  const [totalPoints, setTotalPoints] = useState(0); // State to hold the total points of the user

  useEffect(() => {
    const fetchData = async () => {
      try {
        const gamePoints = await getGamePoints(game.gameId);
        const teams = await getAllTeams();
        const games = await getAllGames();
        const users = await getAllUsers();
        const storedEmail = localStorage.getItem("username");
        const user = users.find((u) => u.email === storedEmail);

        if (!user) {
          setErrorMessage("User not found. Please log in again.");
          return;
        }

        setTotalPoints(user.totalPoints); // Set the user's total points

        const teamMap = teams.reduce((acc, team) => {
          acc[team.teamId] = team.teamName;
          return acc;
        }, {});

        const gameMap = games.reduce((acc, game) => {
          acc[game.gameId] = game.gameName;
          return acc;
        }, {});

        setTeamMap(teamMap);
        setGameMap(gameMap);

        let initialFormData = {
          ...formData,
          gamePoints: gamePoints,
          gameName: gameMap[game.gameId] || game.gameId,
          team: teamMap[game.team1Id] || game.team1Id,
        };

        if (update && betId) {
          const betDetails = await getBettingtableById(betId);
          initialFormData = {
            ...initialFormData,
            bettingPoints: betDetails.betPoints || "",
            team: teamMap[betDetails.teamId] || betDetails.teamId,
          };
        }

        setFormData(initialFormData);
      } catch (error) {
        console.error("There was an error fetching the data!", error);
        setErrorMessage("Failed to fetch game data. Please try again.");
      }
    };

    if (game && game.gameId) {
      fetchData();
    }
  }, [game, update, betId]);

  const handleFormChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const storedEmail = localStorage.getItem("username");
      if (!storedEmail) {
        console.error("Username not found in localStorage");
        setErrorMessage("User not logged in. Please log in and try again.");
        return;
      }

      const users = await getAllUsers();
      const user = users.find((u) => u.email === storedEmail);
      if (!user) {
        setErrorMessage("User not found. Please log in again.");
        return;
      }

      const loginId = user.id;
      const newTotalPoints = totalPoints - parseInt(formData.bettingPoints, 10); // Calculate the new total points

      if (newTotalPoints < 0) {
        setErrorMessage("Insufficient points. Please reduce your bet points.");
        return;
      }

      const dataToSave = {
        loginId: loginId,
        teamSelectedId: formData.fixtureId,
        gameId: formData.gamePoints.gameId,
        teamId: formData.team,
        betPoints: formData.bettingPoints,
      };

      let response;
      if (update) {
        response = await updateBettings(betId, dataToSave);
      } else {
        response = await saveBettings(dataToSave);
      }

      setTotalPoints(newTotalPoints); // Update the total points state
      setShowSuccessMessage(true);
      setTimeout(() => {
        setShowSuccessMessage(false);
        setFormData({
          fixtureId: "",
          gameName: "",
          gamePoints: "",
          team: "",
          result: "pending",
          bettingPoints: "",
          date: "",
        });
        navigate("/Availablegames");
      }, 1000);
    } catch (error) {
      console.error("There was an error saving the bet!", error);
      setErrorMessage("Failed to save the bet. Please bet within your total points.");
      setTimeout(() => setErrorMessage(""), 1000);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeSelect = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <Grid container spacing={3} justifyContent="center" mt={4}>
      <Grid item xs={12} md={4}>
        <Paper elevation={3} sx={{ p: 2, borderRadius: 3 }}>
          <MDBox
            mx={2}
            mt={-5}
            py={3}
            px={2}
            variant="gradient"
            style={{ backgroundColor: "#007BFF" }} // Header background color set to existing button color
            borderRadius="lg"
            coloredShadow="info"
          >
            <MDTypography variant="h6" color="white">
              {" "}
              {/* Text color set to white */}
              {update ? "Update Your Bet" : "Place Your Bet"}
            </MDTypography>
          </MDBox>
          <MDBox pt={3}>
            <form onSubmit={handleSubmit}>
              <TextField
                label="Game Name"
                name="gameName"
                value={formData.gameName}
                onChange={handleFormChange}
                fullWidth
                margin="normal"
                variant="outlined"
                InputProps={{
                  readOnly: true,
                }}
              />
              <TextField
                label="Game Points"
                name="gamePoints"
                value={formData.gamePoints ? formData.gamePoints.gamePoints : ""}
                onChange={handleFormChange}
                fullWidth
                margin="normal"
                variant="outlined"
              />
              <FormControl fullWidth margin="normal" variant="outlined">
                <InputLabel id="team-select-label">Team</InputLabel>
                <Select
                  labelId="team-select-label"
                  id="team-select"
                  name="team"
                  value={formData.team}
                  label="Team"
                  onChange={handleChangeSelect}
                  sx={{
                    padding: "10px",
                    borderRadius: "4px",
                    backgroundColor: "white",
                  }}
                >
                  <MenuItem value={game.team1Id}>{teamMap[game.team1Id]}</MenuItem>
                  <MenuItem value={game.team2Id}>{teamMap[game.team2Id]}</MenuItem>
                </Select>
              </FormControl>
              <TextField
                label="Betting Points"
                name="bettingPoints"
                type="number"
                value={formData.bettingPoints}
                onChange={handleFormChange}
                fullWidth
                margin="normal"
                variant="outlined"
              />
              <TextField
                label="Date"
                name="date"
                type="date"
                value={formData.date}
                onChange={handleFormChange}
                fullWidth
                margin="normal"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <Button
                type="submit"
                variant="contained"
                sx={{
                  mt: 2,
                  color: "#FFF",
                  width: "100%", // Set the button width to 100%
                  height: "50px", // Set the button height to 50px
                  backgroundColor: "#007BFF", // Set the background color to the existing button color
                  borderRadius: "10px", // Adjust the border radius if needed
                  fontSize: "14px", // Adjust the font size if needed
                }}
              >
                {update ? "Update Bet" : "Place Bet"}
              </Button>
              {showSuccessMessage && (
                <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  open={showSuccessMessage}
                  onClose={handleClose}
                  closeAfterTransition
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500,
                  }}
                >
                  <Fade in={showSuccessMessage}>
                    <Box sx={style}>
                      <CheckCircleIcon style={{ fontSize: "4rem", color: "green" }} />
                      <MDTypography id="transition-modal-title" variant="h6" component="h2">
                        Bet Placed Successfully!
                      </MDTypography>
                    </Box>
                  </Fade>
                </Modal>
              )}
              {errorMessage && ( // Display error message if exists
                <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  open={!!errorMessage}
                  onClose={handleClose}
                  closeAfterTransition
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500,
                  }}
                >
                  <Fade in={!!errorMessage}>
                    <Box sx={style}>
                      <MDTypography
                        id="transition-modal-title"
                        variant="h6"
                        component="h2"
                        color="error"
                      >
                        {errorMessage}
                      </MDTypography>
                    </Box>
                  </Fade>
                </Modal>
              )}
            </form>
          </MDBox>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Betting;
