import axios from "axios";

// Define the base URL for available games-related API endpoints
const BASEurl = "https://bettings-429609.dt.r.appspot.com/api/Gaming";

// Function to save a new available game
export const saveAvailableGame = async (gameData) => {
  try {
    const response = await axios.post(`${BASEurl}/save`, gameData);
    console.log("Available game saved successfully:", response.data);
    return response.data;
  } catch (error) {
    console.error("There was an error saving the available game!", error);
    throw error;
  }
};

// Function to get all available games
export const getAllAvailableGames = async () => {
  try {
    const response = await axios.get(`${BASEurl}/getAllFixturesByActive`);
    return response.data;
  } catch (error) {
    console.error("There was an error fetching all available games!", error);
    throw error;
  }
};

export const checkBet = async (loginId, fixtureId) => {
  try {
    const response = await axios.get(`${BASEurl}/check/${loginId}/${fixtureId}`);
    return response.data; // Assuming backend returns the Bettingtable object
  } catch (error) {
    console.error("Error checking bet:", error);
    throw error; // Re-throw the error to handle in the calling function
  }
};

export const getScoreBoardByLoginIdAndGameId = async (loginId, gameId) => {
  try {
    const response = await axios.get(`${BASEurl}/login/${loginId}/game/${gameId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching scoreboard:", error);
    throw error;
  }
};
