import Availablegame from "layouts/Availablegames";
import Betting from "layouts/Betting";
import Configurator from "examples/Configurator";
import CssBaseline from "@mui/material/CssBaseline";
import React, { useEffect, useMemo, useState } from "react";
import Score from "layouts/profile/components/Score.js";
import Sidenav from "examples/Sidenav";
import brandDark from "assets/images/logo-ct-dark.png";
import brandWhite from "assets/images/logo-ct.png";
import createCache from "@emotion/cache";
import routes from "routes";
import rtlPlugin from "stylis-plugin-rtl";
import theme from "assets/theme";
import themeDark from "assets/theme-dark";
import themeDarkRTL from "assets/theme-dark/theme-rtl";
import themeRTL from "assets/theme/theme-rtl";
import { CacheProvider, ThemeProvider } from "@emotion/react";
import { setMiniSidenav, setOpenConfigurator, useMaterialUIController } from "context";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();
  const [isAuthenticated, setIsAuthenticated] = useState(true); // Example authentication state

  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) => {
    return allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });
  };

  const shouldShowSidenav = !["/availablegame", "/betting", "/betting/:game"].some((path) =>
    pathname.includes(path)
  );

  return direction === "rtl" ? (
    <CacheProvider value={rtlCache}>
      <ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL}>
        <CssBaseline />
        {layout === "dashboard" && shouldShowSidenav && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
              brandName="Admin Dashboard"
              routes={routes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <Configurator />
          </>
        )}
        {layout === "vr" && <Configurator />}
        <Routes>
          {getRoutes(routes)}
          {isAuthenticated ? (
            <>
              <Route exact path="/availablegame" element={<Availablegame />} key="availablegame" />
              <Route exact path="/betting/:game" element={<Betting />} key="betting" />
              <Route exact path="/betting" element={<Betting />} key="betting" />
              <Route exact path="/scoreboard" element={<Score />} key="scoreboard" />
            </>
          ) : (
            <Route path="*" element={<Navigate to="/authentication/sign-in" />} />
          )}
          <Route path="/" element={<Navigate to="/authentication/sign-in" />} />
        </Routes>
      </ThemeProvider>
    </CacheProvider>
  ) : (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {layout === "dashboard" && shouldShowSidenav && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
            brandName="Admin Dashboard"
            routes={routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          <Configurator />
        </>
      )}
      {layout === "vr" && <Configurator />}
      <Routes>
        {getRoutes(routes)}
        {isAuthenticated ? (
          <>
            <Route exact path="/availablegame" element={<Availablegame />} key="availablegame" />
            <Route exact path="/betting/:game" element={<Betting />} key="betting" />
            <Route exact path="/betting" element={<Betting />} key="betting" />
            <Route exact path="/scoreboard" element={<Score />} key="scoreboard" />
          </>
        ) : (
          <Route path="*" element={<Navigate to="/authentication/sign-in" />} />
        )}
        <Route path="/" element={<Navigate to="/authentication/sign-in" />} />
      </Routes>
    </ThemeProvider>
  );
}
