import axios from "axios";

// Define the base URL
const BASEurl = "https://bettings-429609.dt.r.appspot.com/api/Gaming";

// Define the method to log in the user
export const loginUser = async (loginDto) => {
  try {
    const response = await axios.post(`${BASEurl}/login`, loginDto);
    console.log("Login successful:", response.data); // Log response data
    console.log(response.data.id);
    sessionStorage.setItem("userId", response.data.id);
    localStorage.setItem("username", loginDto.email);
    return response.data;
  } catch (error) {
    console.error("There was an error logging in!", error);
    throw error; // Re-throw the error to handle it in the calling function if needed
  }
};
