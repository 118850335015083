import axios from "axios";

// Define the base URL for available games-related API endpoints
const BASEurl = "https://bettings-429609.dt.r.appspot.com/api/Gaming";

export const saveBettings = async (gameData) => {
  try {
    const response = await axios.post(`${BASEurl}/addBet`, gameData);
    console.log("Available game saved successfully:", response.data);
    return response.data;
  } catch (error) {
    console.error("There was an error saving the available game!", error);
    throw error;
  }
};

export const updateBettings = (betId, data) => {
  try {
    return axios.put(`${BASEurl}/${betId}/updateBet`, data);
  } catch (error) {
    console.error("There was an error saving the available game!", error);
    throw error;
  }
};

// New function to get betting details by ID
export const getBettingtableById = async (betId) => {
  try {
    const response = await axios.get(`${BASEurl}/${betId}/getBetById`);
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the betting details!", error);
    throw error;
  }
};
