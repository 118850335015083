import axios from "axios";

// Define the base URL with route
const BASEurl = "https://bettings-429609.dt.r.appspot.com/api/Gaming";

// Define the method to authenticate admin
export const adminLogin = async (formData) => {
  try {
    const response = await axios.post(`${BASEurl}/login`, formData);
    console.log("Admin logged in successfully:", response.data);
    return response.data;
  } catch (error) {
    console.error("There was an error logging in as admin:", error);
    throw error; // Re-throw the error to handle it in the calling function if needed
  }
};
