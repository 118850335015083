import axios from "axios";

// Define the base URL with route
const BASEurl = "https://bettings-429609.dt.r.appspot.com/api/Gaming"; // Assuming port 8080

// Fetch all users
export const getAllUsers = async () => {
  try {
    const response = await axios.get(`${BASEurl}/getAllUsers`);
    console.log("Fetched users successfully:", response.data);
    return response.data;
  } catch (error) {
    console.error("There was an error fetching users:", error);
    throw error;
  }
};
