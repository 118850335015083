// FixtureApi.js
import axios from "axios";

const BASE_URL = "https://bettings-429609.dt.r.appspot.com/api/Gaming"; // Adjust if needed

export const getAllFixtures = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/getAllFixtures`);
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error("There was an error fetching all fixtures!", error);
    throw error;
  }
};

export const getActiveFixtures = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/getAllFixturesByActive`);
    return response.data;
  } catch (error) {
    console.error("There was an error fetching active fixtures!", error);
    throw error;
  }
};

export const getFixtureById = async (id) => {
  try {
    const response = await axios.get(`${BASE_URL}/${id}/getFixturesById`);
    return response.data;
  } catch (error) {
    console.error(`There was an error fetching fixture with id ${id}!`, error);
    throw error;
  }
};

export const createFixture = async (fixtureData) => {
  try {
    const response = await axios.post(`${BASE_URL}/addFixture`, fixtureData);
    return response.data;
  } catch (error) {
    console.error("Error creating fixture:", error.message);
    throw error;
  }
};

export const updateFixture = async (fixtureId, fixtureData) => {
  try {
    const response = await axios.put(`${BASE_URL}/${fixtureId}/updateFixture`, fixtureData);
    return response.data;
  } catch (error) {
    console.error("There was an error updating the fixture!", error);
    throw error;
  }
};

export const updateFixtureResult = async (fixtureId, resultData) => {
  try {
    const response = await axios.put(`${BASE_URL}/${fixtureId}/updateResultFixture`, resultData);
    return response.data;
  } catch (error) {
    console.error("There was an error updating the fixture result!", error);
    throw error;
  }
};

export const deleteFixture = async (fixtureId) => {
  try {
    const response = await axios.put(`${BASE_URL}/${fixtureId}/delete/Fixture`);
    return response.data;
  } catch (error) {
    console.error("There was an error deleting the fixture!", error);
    throw error;
  }
};

export const updateFixtureStatus = async (fixtureId, status) => {
  try {
    const response = await axios.put(`${BASE_URL}/${fixtureId}/status/Fixture?status=${status}`);
    return response.data;
  } catch (error) {
    console.error("There was an error updating the fixture status!", error);
    throw error;
  }
};
