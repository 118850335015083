/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { Box, Button, Fade, Grid, Menu, MenuItem, Modal } from "@mui/material";
import { MoreVert as MoreVertIcon, FilterList as FilterListIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { getAllFixtures } from "JavaApi/FixtureApi";
import { getAllGames } from "JavaApi/GameApi";
import { getAllTeams } from "JavaApi/TeamApi";
import { checkBet, getScoreBoardByLoginIdAndGameId } from "JavaApi/AvailablegamesApi";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import CommonTables from "Common/Table";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";

function AvailableGames() {
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [resultData, setResultData] = useState({
    gameName: "",
    team: "",
    totalpoint: "",
    bettingPoint: "",
    score: "",
    result: "",
    date: "",
  });
  const [games, setGames] = useState([]);
  const [selectedGame, setSelectedGame] = useState("");
  const [currentPoints, setCurrentPoints] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);

  const handleCheckResult = async (fixture) => {
    const userId = sessionStorage.getItem("userId");
    try {
      const Resultdata = await checkBet(userId, fixture.fixtureId);
      console.log(Resultdata);
      let result = fixture?.results || "pending";
      setResultData({
        gameName: Resultdata.gameId.gameName,
        team: Resultdata.selectTeam.teamName,
        totalpoint: Resultdata.gameId.gamePoints,
        bettingPoint: Resultdata.betPoints,
        score: Resultdata.totalPoints,
        result: result,
        date: Resultdata.date,
      });

      setOpen(true);
    } catch (error) {
      console.error("Error checking bet:", error);
      alert("Failed to check the bet. Please try again.");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fixtures = await getAllFixtures();
        const teams = await getAllTeams();
        const gamesData = await getAllGames();

        setGames(gamesData);

        const teamMap = teams.reduce((acc, team) => {
          acc[team.teamId] = team.teamName;
          return acc;
        }, {});

        const gameMap = gamesData.reduce((acc, game) => {
          acc[game.gameId] = game.gameName;
          return acc;
        }, {});

        const mappedData = fixtures.map((fixture) => {
          console.log(fixture);
          const resultText = fixture.results ? (
            <span
              style={{
                backgroundColor: "lightblue",
                padding: "5px",
                border: "1px solid #ccc",
                borderRadius: "5px",
                display: "inline-block",
              }}
            >
              Result Updated: {fixture.results}
            </span>
          ) : (
            ""
          );

          let actions;
          if (fixture.results === null) {
            actions = <ActionsMenu fixture={fixture} />;
          } else {
            actions = resultText;
          }

          return {
            fixtureId: fixture.fixtureId,
            game: gameMap[fixture.gameId],
            team1: teamMap[fixture.team1Id],
            team2: teamMap[fixture.team2Id],
            date: fixture.setDate,
            Views: (
              <Button
                variant="contained"
                onClick={() => handleCheckResult(fixture)}
                sx={{ color: "#FFFFFF" }}
              >
                Score
              </Button>
            ),
            actions: actions,
          };
        });

        setRows(mappedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const handleGameSelect = async (gameId) => {
    setSelectedGame(gameId);
    handleFilterClose();

    if (gameId) {
      const loginId = sessionStorage.getItem("userId");
      try {
        const scoreboard = await getScoreBoardByLoginIdAndGameId(loginId, gameId);
        setCurrentPoints(scoreboard.totalPoints);
      } catch (error) {
        console.error("Error fetching scoreboard:", error);
        setCurrentPoints("N/A");
      }
    } else {
      setCurrentPoints("");
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const ActionsMenu = ({ fixture }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [betData, setBetData] = useState(null);
    const [showOptions, setShowOptions] = useState(true);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();

    useEffect(() => {
      const fetchData = async () => {
        if (fixture?.results !== null) {
          setShowOptions(false);
        } else {
          const userId = sessionStorage.getItem("userId");
          try {
            const result = await checkBet(userId, fixture.fixtureId);
            console.log(result);
            setBetData(result);
          } catch (error) {
            console.error("Error fetching bet data:", error);
          }
        }
      };

      fetchData();
    }, [fixture]);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleRowClick = () => {
      navigate(`/betting/${fixture.game}`, { state: { fixture } });
      handleClose();
    };

    const handleUpdateClick = () => {
      const betId = betData ? betData.betId : null;
      navigate(`/betting/${fixture.game}`, { state: { fixture, update: 1, betId } });
      handleClose();
    };

    if (!showOptions) {
      return null;
    }
    console.log(betData);
    return (
      <>
        <Button variant="contained" onClick={handleClick} sx={{ color: "#FFFFFF" }}>
          <MoreVertIcon />
        </Button>
        <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
          {betData && betData.d !== null ? (
            "sorry you already placed your bet"
          ) : (
            <MenuItem onClick={handleRowClick}>Place Bet</MenuItem>
          )}
        </Menu>
      </>
    );
  };

  const enhancedColumns = [
    { Header: "Game", accessor: "game", align: "left" },
    { Header: "Team 1", accessor: "team1", align: "left" },
    { Header: "Team 2", accessor: "team2", align: "left" },
    { Header: "Date", accessor: "date", align: "left" },
    { Header: "View", accessor: "Views", align: "left" },
    { Header: "Actions", accessor: "actions", align: "left" },
    // { Header: "TotalPoints", accessor: "totalPoints", align: "left" },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container spacing={3} justifyContent="flex-end" alignItems="center" mt={4}>
        <Grid item>
          <MDBox display="flex" gap={2}>
            <Button
              variant="contained"
              startIcon={<FilterListIcon />}
              onClick={handleFilterClick}
              sx={{
                color: "#fff",
                backgroundColor: "#1976d2",
                borderRadius: "4px",
              }}
            >
              Total Points
            </Button>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleFilterClose}>
              <MenuItem onClick={() => handleGameSelect("")}>All Games</MenuItem>
              {games.map((game) => (
                <MenuItem key={game.gameId} onClick={() => handleGameSelect(game.gameId)}>
                  {game.gameName}
                </MenuItem>
              ))}
            </Menu>
            {selectedGame && (
              <MDTypography variant="body2" fontWeight="regular" color="text" mr={2}>
                Current Points: {currentPoints}
              </MDTypography>
            )}
            <Button
              variant="contained"
              sx={{
                color: "#fff",
                backgroundColor: "#1976d2",
                borderRadius: "4px",
              }}
              onClick={() => navigate("/score")}
            >
              View Scoreboard
            </Button>
          </MDBox>
        </Grid>
      </Grid>
      <MDBox pt={6} pb={3}>
        <CommonTables name={"Available Games"} columns={enhancedColumns} rows={rows} />
      </MDBox>
      <Modal open={open} onClose={handleClose} closeAfterTransition>
        <Fade in={open}>
          <Box sx={style}>
            <MDBox
              mx={2}
              mt={-5}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white" align="center">
                Bet Result
              </MDTypography>
            </MDBox>
            <MDBox pt={3}>
              <Box mt={2}>
                <MDTypography variant="body1">Game Name: {resultData?.gameName}</MDTypography>
                <MDTypography variant="body1">Team: {resultData?.team}</MDTypography>
                <MDTypography variant="body1">My Score: {resultData?.score}</MDTypography>
                <MDTypography variant="body1">
                  Betting Points: {resultData?.bettingPoint}
                </MDTypography>
                <MDTypography variant="body1">Game Points: {resultData?.totalpoint}</MDTypography>
                <MDTypography variant="body1">Result: {resultData?.result}</MDTypography>
                <MDTypography variant="body1">Date: {resultData?.date}</MDTypography>
              </Box>
            </MDBox>
          </Box>
        </Fade>
      </Modal>
    </DashboardLayout>
  );
}

export default AvailableGames;
