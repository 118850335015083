import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MDBox from "components/MDBox";
import MenuItem from "@mui/material/MenuItem";
import Modifytable from "Common/Table/Modifytable";
import React, { useEffect, useState } from "react";
import Select from "@mui/material/Select";
import { getAllGames } from "JavaApi/GameApi";
import { getAllScoreGames } from "JavaApi/ScoreApi";
import { getAllUsers } from "JavaApi/TeamApi";

export const scoreColumn = [
  { Header: "EMPLOYEENAME", accessor: "name", align: "left" },
  { Header: "GAMENAME", accessor: "gameName", align: "left" },
  { Header: "GAMEPOINTS", accessor: "points", align: "left" },
  { Header: "BETTINGPOINTS", accessor: "bettingPoints", align: "left" },
  { Header: "TOTAL POINTS", accessor: "totalPoints", align: "left" },
];

const Scoreboard = () => {
  const [games, setGames] = useState([]);
  const [bets, setBets] = useState([]);
  const [selectedGame, setSelectedGame] = useState("");
  const [rows, setRows] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fixtures = await getAllScoreGames();
        const games = await getAllGames();
        const users = await getAllUsers();
        console.log(users);

        // Get the stored email from localStorage
        const storedEmail = localStorage.getItem("username");
        if (!storedEmail) {
          console.error("Username not found in localStorage");
          setErrorMessage("User not logged in. Please log in and try again.");
          return;
        }

        // Find the current user
        const currentUser = users.find((u) => u.email === storedEmail);
        if (!currentUser) {
          console.error("User not found for email:", storedEmail);
          setErrorMessage("User not found. Please log in again.");
          return;
        }

        console.log("Current user:", currentUser);

        // Create a mapping of gameId to gameName and gamePoints
        const gameMap = games.reduce((acc, game) => {
          acc[game.gameId] = { gameName: game.gameName, gamePoints: game.gamePoints };
          return acc;
        }, {});

        // Create rows for the table, including all users
        const mappedData = fixtures.map((fixture) => {
          const gameData = gameMap[fixture.gameId] || {};
          const user = users.find((u) => u.id === fixture.loginId) || {};
          return {
            name: user.userName || "Unknown",
            gameName: gameData.gameName || "Unknown",
            points: gameData.gamePoints || 0,
            bettingPoints: fixture.betPoints,
            totalPoints: fixture.totalPoints,
            isCurrentUser: user.id === currentUser.id, // Flag to identify the current user
          };
        });

        setRows(mappedData);
        setGames(Object.values(gameMap).map((game) => game.gameName));
        setBets(fixtures);
      } catch (error) {
        console.error("Error fetching data:", error);
        setErrorMessage("Failed to fetch data. Please try again.");
      }
    };

    fetchData();
  }, []);
  const handleGameChange = (e) => {
    setSelectedGame(e.target.value);
  };

  const filteredRows = rows.filter((row) => (selectedGame ? row.gameName === selectedGame : true));
  console.log(filteredRows);
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card sx={{ border: "none", boxShadow: "none" }}>
          <MDBox display="flex" alignItems="center" py={2} px={3}>
            <FormControl variant="outlined">
              <InputLabel id="game-select-label" sx={{ color: "#666" }}>
                Select Game
              </InputLabel>
              <Select
                labelId="game-select-label"
                value={selectedGame}
                onChange={handleGameChange}
                label="Select game"
                sx={{ minWidth: 200, maxWidth: 200, height: 50 }}
              >
                <MenuItem value="">
                  <em>All Games</em>
                </MenuItem>
                {games.map((game, index) => (
                  <MenuItem key={index} value={game}>
                    {game}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </MDBox>
          <MDBox pt={3} px={3} pb={3}>
            <Modifytable
              name="Scoreboard"
              columns={scoreColumn}
              rows={filteredRows}
              sx={{
                "& .MuiTableCell-root": {
                  borderBottom: "none",
                },
                "& .MuiTableRow-root:nth-of-type(even)": {
                  backgroundColor: "#f5f5f5",
                },
              }}
            />
          </MDBox>
        </Card>
      </Grid>
    </Grid>
  );
};

export default Scoreboard;
