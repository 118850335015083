import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CommonTables from "Common/Table";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  IconButton,
  Checkbox,
  FormControlLabel,
  Modal,
  FormHelperText,
  Alert,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import ResultIcon from "@mui/icons-material/Assessment";
import DeleteIcon from "@mui/icons-material/Delete";
import { fixtureColumn } from "layouts/tables/data/employeeTableData";
import {
  getActiveFixtures,
  getFixtureById,
  createFixture,
  updateFixture,
  deleteFixture,
  updateFixtureResult,
} from "JavaApi/FixtureApi";
import { getAllGames } from "JavaApi/GameApi";
import { getAllTeams } from "JavaApi/TeamApi";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const Fixtures = () => {
  const [games, setGames] = useState([]);
  const [formData, setFormData] = useState({
    fixtureId: "",
    gameId: "",
    team1Id: "",
    team2Id: "",
    setDate: "",
  });
  const [open, setOpen] = useState(false);
  const [resultData, setResultData] = useState({
    fixtureId: "",
    gameId: "",
    team1Id: "",
    team2Id: "",
    winner: "",
  });
  const [allGames, setAllGames] = useState([]);
  const [allTeams, setAllTeams] = useState([]);
  const [selectedGame, setSelectedGame] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [error, setError] = useState("");
  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const gamesData = await getAllGames();
        setAllGames(gamesData);
        const teamsData = await getAllTeams();
        setAllTeams(teamsData);
        const fixturesData = await getActiveFixtures();
        // Sort fixtures in reverse chronological order
        const sortedFixtures = fixturesData.sort(
          (a, b) => new Date(b.setDate) - new Date(a.setDate)
        );
        setGames(sortedFixtures);
      } catch (error) {
        console.error("There was an error fetching the data!", error);
        setError("Failed to fetch initial data. Please try again.");
      }
    };

    fetchData();
  }, []);

  const handleOpen = (game) => {
    if (game.winner) {
      setError("Result has already been announced for this fixture.");
    } else {
      setResultData({
        fixtureId: game.fixtureId,
        gameId: game.gameId,
        team1Id: game.team1Id,
        team2Id: game.team2Id,
        winner: "",
      });
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setError("");
  };

  const handleResultChange = (team) => {
    setResultData((prevData) => ({
      ...prevData,
      winner: prevData.winner === team ? "" : team,
    }));
  };

  const handleResultSubmit = async (e) => {
    e.preventDefault();

    // Check if winner is selected
    if (!resultData.winner) {
      setError("Please select a winner before submitting.");
      return;
    }

    try {
      // Fetch fixture details
      const fixtureDetails = await getFixtureById(resultData.fixtureId);

      // Check if result has already been announced
      if (fixtureDetails.results && fixtureDetails.results !== "") {
        setError("Result has already been announced for this fixture and cannot be updated.");
        return;
      }

      // Update fixture result
      await updateFixtureResult(resultData.fixtureId, {
        teamId: resultData.winner,
        results: resultData.winner, // Assuming the winner's name or ID is stored in results
      });

      // Clear modal and fetch updated fixtures
      setOpen(false);
      const updatedFixtures = await getActiveFixtures();
      setGames(updatedFixtures);
      setError("");
    } catch (error) {
      console.error("Error updating fixture result:", error);
      setError("Failed to update fixture result. Please try again.");
    }
  };

  const handleFormChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    // Clear the error for this field when the user starts typing
    setFormErrors((prev) => ({ ...prev, [e.target.name]: "" }));
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.gameId) errors.gameId = "Game is required";
    if (!formData.team1Id) errors.team1Id = "Team 1 is required";
    if (!formData.team2Id) errors.team2Id = "Team 2 is required";
    if (!formData.setDate) errors.setDate = "Date is required";
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
      let newFixture;
      if (isEditing) {
        newFixture = await updateFixture(formData.fixtureId, formData);
        // Remove the old fixture from the list
        setGames((prevGames) => prevGames.filter((game) => game.fixtureId !== formData.fixtureId));
      } else {
        newFixture = await createFixture(formData);
      }

      // Add the new or updated fixture to the beginning of the array
      setGames((prevGames) => [newFixture, ...prevGames]);

      setIsEditing(false);
      setFormData({
        fixtureId: "",
        gameId: "",
        team1Id: "",
        team2Id: "",
        setDate: "",
      });
      setError("");
    } catch (error) {
      console.error("Error creating/updating fixture:", error);
      setError("Failed to create/update fixture. Please try again.");
    }
  };

  const handleChangeSelect = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });

    if (name === "gameId") {
      const selectedGame = allGames.find((game) => game.gameId === value);
      setSelectedGame(selectedGame);
    }

    // Clear the error for this field when the user makes a selection
    setFormErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const handleEdit = async (fixtureId) => {
    try {
      const fixtureDetails = await getFixtureById(fixtureId);
      setFormData({
        fixtureId: fixtureDetails.fixtureId,
        gameId: fixtureDetails.gameId,
        team1Id: fixtureDetails.team1Id,
        team2Id: fixtureDetails.team2Id,
        setDate: fixtureDetails.setDate,
      });
      setIsEditing(true);
    } catch (error) {
      console.error("Error fetching fixture details:", error);
      setError("Failed to fetch fixture details. Please try again.");
    }
  };

  const handleDelete = async (fixtureId) => {
    try {
      await deleteFixture(fixtureId);
      setGames((prevGames) => prevGames.filter((game) => game.fixtureId !== fixtureId));
    } catch (error) {
      console.error("Error deleting fixture:", error);
      setError("Failed to delete fixture. Please try again.");
    }
  };

  const updatedRows = games
    .filter((row) => row.status === true)
    .map((row) => ({
      ...row,
      team1Id: allTeams.find((team) => team.teamId === row.team1Id)?.teamName || row.team1Id,
      team2Id: allTeams.find((team) => team.teamId === row.team2Id)?.teamName || row.team2Id,
      actions: (
        <>
          <IconButton onClick={() => handleEdit(row.fixtureId)}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleOpen(row)}>
            <ResultIcon />
          </IconButton>
          <IconButton onClick={() => handleDelete(row.fixtureId)}>
            <DeleteIcon />
          </IconButton>
        </>
      ),
    }));

  return (
    <Box mt={4} px={2}>
      {error && (
        <Box mb={2}>
          <Alert severity="error" onClose={() => setError("")}>
            {error}
          </Alert>
        </Box>
      )}
      <Grid container spacing={3}>
        <CommonTables
          name={"Fixture Table"}
          columns={[...fixtureColumn, { Header: "Actions", accessor: "actions" }]}
          rows={updatedRows}
        />
        <Grid item xs={12} md={4}>
          <Paper elevation={3} sx={{ p: 2, borderRadius: 3 }}>
            <MDBox
              mx={2}
              mt={-5}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                {isEditing ? "Edit Game" : "Create Game"}
              </MDTypography>
            </MDBox>
            <MDBox pt={3}>
              <form onSubmit={handleSubmit}>
                <input type="hidden" name="fixtureId" value={formData.fixtureId} />
                <FormControl
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  error={!!formErrors.gameId}
                >
                  <InputLabel id="game-select-label">Game</InputLabel>
                  <Select
                    labelId="game-select-label"
                    id="game-select"
                    name="gameId"
                    value={formData.gameId}
                    label="Game"
                    onChange={handleChangeSelect}
                    sx={{
                      padding: "10px",
                      borderRadius: "4px",
                      backgroundColor: "white",
                    }}
                  >
                    <MenuItem value="">
                      <em>Select Game</em>
                    </MenuItem>
                    {allGames
                      .filter((game) => game.status)
                      .map((game) => (
                        <MenuItem key={game.gameId} value={game.gameId}>
                          {game.gameName}
                        </MenuItem>
                      ))}
                  </Select>
                  {formErrors.gameId && <FormHelperText>{formErrors.gameId}</FormHelperText>}
                </FormControl>
                <FormControl
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  error={!!formErrors.team1Id}
                >
                  <InputLabel id="team1-select-label">Team 1</InputLabel>
                  <Select
                    labelId="team1-select-label"
                    id="team1-select"
                    name="team1Id"
                    value={formData.team1Id}
                    label="Team 1"
                    onChange={handleChangeSelect}
                    sx={{
                      padding: "10px",
                      borderRadius: "4px",
                      backgroundColor: "white",
                    }}
                  >
                    <MenuItem value="">
                      <em>Select Team 1</em>
                    </MenuItem>
                    {allTeams
                      .filter((team) => team.status)
                      .map((team) => (
                        <MenuItem key={team.teamId} value={team.teamId}>
                          {team.teamName}
                        </MenuItem>
                      ))}
                  </Select>
                  {formErrors.team1Id && <FormHelperText>{formErrors.team1Id}</FormHelperText>}
                </FormControl>
                <FormControl
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  error={!!formErrors.team2Id}
                >
                  <InputLabel id="team2-select-label">Team 2</InputLabel>
                  <Select
                    labelId="team2-select-label"
                    id="team2-select"
                    name="team2Id"
                    value={formData.team2Id}
                    label="Team 2"
                    onChange={handleChangeSelect}
                    sx={{
                      padding: "10px",
                      borderRadius: "4px",
                      backgroundColor: "white",
                    }}
                  >
                    <MenuItem value="">
                      <em>Select Team 2</em>
                    </MenuItem>
                    {allTeams
                      .filter((team) => team.status)
                      .map((team) => (
                        <MenuItem key={team.teamId} value={team.teamId}>
                          {team.teamName}
                        </MenuItem>
                      ))}
                  </Select>
                  {formErrors.team2Id && <FormHelperText>{formErrors.team2Id}</FormHelperText>}
                </FormControl>
                <TextField
                  label="Date"
                  name="setDate"
                  type="date"
                  value={formData.setDate}
                  onChange={handleFormChange}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    min: selectedGame ? selectedGame.startDate : "",
                    max: selectedGame ? selectedGame.endDate : "",
                  }}
                  error={!!formErrors.setDate}
                  helperText={formErrors.setDate}
                />
                <Box mt={2}>
                  <Button type="submit" variant="contained" fullWidth sx={{ color: "#fff" }}>
                    {isEditing ? "Update" : "Submit"}
                  </Button>
                </Box>
              </form>
            </MDBox>
          </Paper>
        </Grid>
      </Grid>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...style, width: 350, borderRadius: 3 }}>
          <MDBox
            mx={2}
            mt={-7}
            py={3}
            px={2}
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
          >
            <MDTypography variant="h6" color="white">
              Result
            </MDTypography>
          </MDBox>
          <MDBox pt={3}>
            <form onSubmit={handleResultSubmit}>
              <TextField
                label="Team 1"
                name="team1"
                value={
                  resultData.team1Id
                    ? allTeams.find((team) => team.teamId === resultData.team1Id)?.teamName
                    : ""
                }
                fullWidth
                margin="normal"
                variant="outlined"
                InputProps={{
                  readOnly: true,
                }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={resultData.winner === resultData.team1Id}
                    onChange={() => handleResultChange(resultData.team1Id)}
                  />
                }
                label="Won"
              />
              <TextField
                label="Team 2"
                name="team2"
                value={
                  resultData.team2Id
                    ? allTeams.find((team) => team.teamId === resultData.team2Id)?.teamName
                    : ""
                }
                fullWidth
                margin="normal"
                variant="outlined"
                InputProps={{
                  readOnly: true,
                }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={resultData.winner === resultData.team2Id}
                    onChange={() => handleResultChange(resultData.team2Id)}
                  />
                }
                label="Won"
              />
              {error && (
                <Box mt={2}>
                  <Alert severity="error" onClose={() => setError("")}>
                    {error}
                  </Alert>
                </Box>
              )}
              <Box mt={2}>
                <Button type="submit" variant="contained" fullWidth sx={{ color: "#fff" }}>
                  Submit
                </Button>
              </Box>
            </form>
          </MDBox>
        </Box>
      </Modal>
    </Box>
  );
};

export default Fixtures;
