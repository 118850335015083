import axios from "axios";

const BASEurl = "https://bettings-429609.dt.r.appspot.com/api/Gaming";

// Fetch all games
export const getAllGames = async () => {
  try {
    const response = await axios.get(`${BASEurl}/getAllGames`);
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the games!", error);
    throw error;
  }
};

// Fetch all users
export const getAllUsers = async () => {
  try {
    const response = await axios.get(`${BASEurl}/getAllUsers`);
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the users!", error);
    throw error;
  }
};

// Save a new team
export const addTeam = async (teamData) => {
  try {
    const response = await axios.post(`${BASEurl}/addTeam`, teamData);
    console.log("Team added successfully:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error adding team:", error);
    throw error;
  }
};

// Update a team by teamId
export const updateTeam = async (teamId, teamData) => {
  try {
    const response = await axios.put(`${BASEurl}/${teamId}/edit/team`, teamData);
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error("There was an error updating the team!", error);
    throw error;
  }
};

// Delete (soft delete) a team by teamId
export const deleteTeam = async (teamId) => {
  try {
    const response = await axios.put(`${BASEurl}/${teamId}/delete/team`);
    return response.data;
  } catch (error) {
    console.error("There was an error deleting the team!", error);
    throw error;
  }
};

// Update the status of a team by teamId
export const updateTeamStatus = async (teamId, status) => {
  try {
    const response = await axios.put(`${BASEurl}/${teamId}/status/team`, null, {
      params: { status },
    });
    return response.data;
  } catch (error) {
    console.error("There was an error updating the team status!", error);
    throw error;
  }
};

// Fetch all teams
export const getAllTeams = async () => {
  try {
    const response = await axios.get(`${BASEurl}/getAllTeams`);
    console.log("Fetched teams successfully:", response.data);
    return response.data;
  } catch (error) {
    console.error("There was an error fetching teams:", error);
    throw error;
  }
};

export const getTeamById = async (id) => {
  try {
    const response = await axios.get(`${BASEurl}/${id}/getTeamById`);
    console.log("Fetched team successfully:", response.data);
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the team:", error);
    throw error;
  }
};
