import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CancelIcon from "@mui/icons-material/Cancel";
import CommonTables from "Common/Table";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Paper from "@mui/material/Paper";
import React, { useEffect, useState, useCallback } from "react";
import TextField from "@mui/material/TextField";
import { teamColumns } from "layouts/tables/data/employeeTableData";
import Alert from "@mui/material/Alert";

import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  useTheme,
  Chip,
  FormHelperText,
} from "@mui/material";
import {
  getAllGames,
  getAllUsers,
  addTeam,
  updateTeam,
  deleteTeam,
  getAllTeams,
  getTeamById,
} from "JavaApi/TeamApi";

const TeamCreation = () => {
  const [teams, setTeams] = useState([]);
  const [games, setGames] = useState([]);
  const [users, setUsers] = useState([]);
  const [formData, setFormData] = useState({
    gameId: "",
    teamName: "",
    teamMembers: [],
    team1Ids: [],
  });
  const [isEditing, setIsEditing] = useState(false);
  const [editTeamId, setEditTeamId] = useState(null);
  const [errors, setErrors] = useState({});
  const [submissionError, setSubmissionError] = useState("");

  const fetchInitialData = useCallback(async () => {
    try {
      const [gamesData, usersData, teamsData] = await Promise.all([
        getAllGames(),
        getAllUsers(),
        getAllTeams(),
      ]);

      const activeGames = gamesData.filter((game) => game.status === true);
      setGames(activeGames);

      const nonAdminUsers = usersData.filter((user) => user.isAdmin === false);
      setUsers(nonAdminUsers);

      const activeTeams = teamsData.filter((team) => team.status === true);
      setTeams(activeTeams);
    } catch (error) {
      console.error("Error fetching initial data:", error);
    }
  }, []);

  useEffect(() => {
    fetchInitialData();
  }, [fetchInitialData]);

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: undefined }));
    setSubmissionError("");
  };

  const handleGameChange = (e) => {
    const gameId = e.target.value;
    handleFormChange(e);
    setFormData((prevFormData) => ({
      ...prevFormData,
      gameId,
      teamMembers: [],
      team1Ids: [],
    }));
  };

  const handleMultiSelect = (event) => {
    const { value } = event.target;
    const selectedUserNames = value;
    const selectedUsers = users?.filter((user) => selectedUserNames.includes(user.userName));
    const teamIds = selectedUsers?.map((user) => user.id);

    setFormData((prevFormData) => ({
      ...prevFormData,
      teamMembers: selectedUserNames,
      team1Ids: teamIds,
    }));
    setErrors((prevErrors) => ({ ...prevErrors, teamMembers: undefined }));
    setSubmissionError("");
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.gameId) newErrors.gameId = "Game is required";
    if (!formData.teamName) newErrors.teamName = "Team name is required";
    if (formData.teamMembers.length === 0)
      newErrors.teamMembers = "At least one team member is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const checkTeamMembersAvailability = () => {
    const existingTeamMembers = teams
      .filter((team) => team.gameId === formData.gameId && team.teamId !== editTeamId)
      .flatMap((team) => team.team1Ids);

    const unavailableMembers = formData.team1Ids.filter((id) => existingTeamMembers.includes(id));

    if (unavailableMembers.length > 0) {
      const unavailableUserNames = unavailableMembers
        .map((id) => users.find((user) => user.id === id)?.userName)
        .filter(Boolean);

      setSubmissionError(
        `The following users are already in another team for this game: ${unavailableUserNames.join(
          ", "
        )}`
      );
      return false;
    }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    if (!checkTeamMembersAvailability()) return;

    try {
      if (isEditing) {
        const updatedTeam = await updateTeam(editTeamId, formData);
        setTeams(teams.map((team) => (team.teamId === editTeamId ? updatedTeam : team)));
        setIsEditing(false);
        setEditTeamId(null);
      } else {
        const newTeam = await addTeam(formData);
        setTeams([...teams, newTeam]);
      }

      handleClear();
    } catch (error) {
      console.error("Error submitting the form:", error);
      setSubmissionError("The selected team member is already in another team for the same game .");
    }
  };

  const handleEdit = async (teamId) => {
    try {
      const teamToEdit = await getTeamById(teamId);
      console.log(teamToEdit);

      const teamMemberNames =
        teamToEdit.team1Ids?.map((id) => {
          const user = users.find((user) => user.id === id);
          return user ? user.userName : "";
        }) || [];

      setFormData({
        gameId: teamToEdit.gameId,
        teamName: teamToEdit.teamName,
        teamMembers: teamMemberNames,
        team1Ids: teamToEdit.team1Ids,
      });

      setIsEditing(true);
      setEditTeamId(teamId);
    } catch (error) {
      console.error("Error fetching team data:", error);
    }
  };

  const handleDelete = async (teamId) => {
    try {
      await deleteTeam(teamId);
      setTeams(teams.filter((team) => team.teamId !== teamId));
    } catch (error) {
      console.error("Error deleting the team:", error);
    }
  };

  const handleRemoveMember = (memberToRemove) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      teamMembers: prevFormData.teamMembers?.filter((member) => member !== memberToRemove) || [],
      team1Ids:
        prevFormData.team1Ids?.filter(
          (id) => users.find((user) => user.id === id)?.userName !== memberToRemove
        ) || [],
    }));
  };

  const handleClear = () => {
    setFormData({
      gameId: "",
      teamName: "",
      teamMembers: [],
      team1Ids: [],
    });
    setIsEditing(false);
    setEditTeamId(null);
    setErrors({});
    setSubmissionError("");
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const theme = useTheme();

  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName?.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  const updatedRows = teams?.map((row) => ({
    ...row,
    team1Ids: Array.isArray(row.team1Ids) ? row.team1Ids.join(", ") : row.team1Ids,
    actions: (
      <>
        <IconButton onClick={() => handleEdit(row.teamId)}>
          <EditIcon />
        </IconButton>
        <IconButton onClick={() => handleDelete(row.teamId)}>
          <DeleteIcon />
        </IconButton>
      </>
    ),
  }));

  return (
    <Box mt={4} px={2}>
      <Grid container spacing={3}>
        <CommonTables name={"Team Table"} columns={teamColumns} rows={updatedRows} />
        <Grid item xs={12} md={4}>
          <Paper elevation={3} sx={{ p: 2, borderRadius: 3 }}>
            <MDBox
              mx={2}
              mt={-5}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                {isEditing ? "Edit Team" : "Create Team"}
              </MDTypography>
            </MDBox>
            <MDBox pt={3}>
              {submissionError && (
                <Alert severity="error" sx={{ mb: 2 }}>
                  {submissionError}
                </Alert>
              )}
              <form onSubmit={handleSubmit}>
                <FormControl fullWidth margin="normal" variant="outlined" error={!!errors.gameId}>
                  <InputLabel id="game-select-label">Game</InputLabel>
                  <Select
                    labelId="game-select-label"
                    id="game-select"
                    name="gameId"
                    value={formData.gameId}
                    label="Game"
                    onChange={handleGameChange}
                    sx={{
                      padding: "10px",
                      borderRadius: "4px",
                      backgroundColor: "white",
                    }}
                  >
                    <MenuItem value="">
                      <em>Select Game</em>
                    </MenuItem>
                    {games?.map((game) => (
                      <MenuItem key={game.gameId} value={game.gameId}>
                        {game.gameName}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.gameId && <FormHelperText>{errors.gameId}</FormHelperText>}
                </FormControl>
                <TextField
                  label="Team Name"
                  name="teamName"
                  value={formData.teamName}
                  onChange={handleFormChange}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  error={!!errors.teamName}
                  helperText={errors.teamName}
                />
                <FormControl
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  error={!!errors.teamMembers}
                >
                  <InputLabel id="team-members-select-label">Team Members</InputLabel>
                  <Select
                    labelId="team-members-select-label"
                    id="team-members-select"
                    multiple
                    value={formData.teamMembers}
                    onChange={handleMultiSelect}
                    input={<OutlinedInput label="Team Members" />}
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((userName) => (
                          <Chip
                            key={userName}
                            label={userName}
                            deleteIcon={
                              <CancelIcon onMouseDown={(event) => event.stopPropagation()} />
                            }
                            onDelete={() => handleRemoveMember(userName)}
                          />
                        ))}
                      </Box>
                    )}
                    MenuProps={MenuProps}
                    sx={{
                      padding: "10px",
                      borderRadius: "4px",
                      backgroundColor: "white",
                    }}
                  >
                    {users?.map((user) => (
                      <MenuItem
                        key={user.id}
                        value={user.userName}
                        style={getStyles(user.userName, formData?.teamMembers || [], theme)}
                      >
                        {user.userName}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.teamMembers && <FormHelperText>{errors.teamMembers}</FormHelperText>}
                </FormControl>
                <MDBox mt={2} mb={1}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{ borderRadius: 3 }}
                  >
                    {isEditing ? "Update Team" : "Create Team"}
                  </Button>
                </MDBox>
              </form>
            </MDBox>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TeamCreation;
